<template>
  <div>
    <iframe :src="url" frameborder="0" width="100%" height="850px"></iframe>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      url: "https://www.baidu.com/",
    };
  },
  methods: {},
  mounted() {
    console.log(window.location.href);
    console.log(window.location.href.indexOf("?url="));
    console.log(
      window.location.href.slice(window.location.href.indexOf("?url=") + 5)
    );
  },
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
</style>